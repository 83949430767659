import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#F08300';
const footerRelatedLinks = [
	{
		title: 'Crowsnest Properties Branding',
		url: '/en/projects/crowsnest-properties-branding/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				image_1: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange-2/SUPT_TDH_Interface-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange-2/SUPT_TDH_Interface-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/terre-des-hommes-my-orange/',
					lang: 'fr',
				},
			]}
			title="Terre des Hommes, My orange - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Terre des Hommes, My orange"
				video={{
					id: '3745d1e721a246ee82db38697124f5b4',
					alt: '',
				}}
			/>

			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							The gamified version of the Terre des hommes oranges
							campaign.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Research',
								'Concept',
								'UX/UI',
								'Motion Design',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The context of the project</h3>
						<p>
							In 2020, health restrictions put a stop to the
							famous street sale of oranges organized by Terre des
							hommes. During COVID, we created a digital version
							of the Terre des Hommes orange campaign. In 2022,
							the NGO wanted to launch an updated version of the
							game with a new playful concept.
						</p>

						<h3>Our response</h3>
						<p>
							This digital gamification campaign takes the visitor
							into a world full of animated illustrations that
							show the work the NGO does in the field. Each
							visitor is set the challenge of finding as many
							oranges as possible within a short period of time.
							The more oranges they find, the greater the impact
							on the field.
						</p>
						<p>
							The illustrations for the 2022 edition were created
							in collaboration with the talented Catherine
							Pearson.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="cc96681ebc287badca01a8daa6c0c079"
					alt=""
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Video src="10d5f84f4db3c3ca961fdbf383eed14f" alt="" />
					<Image {...data.image_1.childImageSharp} alt="Interface" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							A light-hearted and positive way to raise awareness
						</h3>
						<p>
							Once the game is over, the player can donate the
							total number of oranges collected in the basket.
							They can also customize the number of oranges,
							allowing them to choose the exact amount they wish
							to donate.
						</p>
						<p>
							To encourage them to make the donation, we highlight
							the concrete benefits this donation will translate
							to on the ground. All that remains is for them to
							take action.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_2.childImageSharp}
							alt="Interface"
						/>
						<Video
							fluid={[
								{
									src: '7c1da20247af3e1d63361ebc944bafd0',
								},
								{
									src: 'fac5fbe9911e6778124aba3d72e0cfc3',
									minWidth: 768,
								},
							]}
							alt=""
							className="grid-spacing"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
